/* Variables */
:root {
  --black: #000000;
  --white: #ffffff;

  --black-hsl: 0, 0%, 0%;
  --white-hsl: 0, 0%, 100%;

  --pageBG: #f2f2f2;
  --pageBGDark: #9b7524;
  --pageText: #9b7524;

  --pagePrimary: #2b6647;
  --pageSecondary: #f4c66c;
  --pageHighlight: #ffe400;
  --pageDark: #000;

  --pageBG-hsl: 60, 14%, 95%;
  --pageText-hsl: 0, 0%, 0%;
}

[data-theme='metal'] {
  --pageBG: #000000;
  --pageText: #8fff1f;

  --pageBG-hsl: 0, 0%, 0%;
  --pageText-hsl: 90, 100%, 56%;
}

@custom-media --xs (min-width: 480px);
@custom-media --sm (min-width: 768px);
@custom-media --md (min-width: 940px);
@custom-media --lg (min-width: 1200px);
@custom-media --xl (min-width: 1600px);

@custom-media --xsMax (max-width: 479px);
@custom-media --smMax (max-width: 767px);
@custom-media --mdMax (max-width: 939px);
@custom-media --lgMax (max-width: 1199px);
@custom-media --xlMax (max-width: 1599px);

.product {
  /* @apply border-b; */
  @apply py-12;

  &--candle-properties {
    @apply mt-6;
  }

  &--content {
    @apply relative grid md:grid-cols-2 md:bg-pageText;
  }

  &--gallery {
    @apply relative mb-12 md:mb-0  bg-pageBG border-b md:border-none;

    .carousel {
      @apply max-w-5xl mx-auto;
    }
  }

  &--details {
    @apply bg-pageBG flex flex-col;
  }

  &--info {
    @apply w-full max-w-xl p-4 sm:p-8;
  }
  &--desc {
    @apply mt-3;
  }

  &--header {
    @apply flex pb-2;

    .price {
      @apply flex flex-col items-end mt-auto ml-auto;

      &--current {
        @apply leading-normal;
      }

      &--discount {
        @apply mb-2 p-1 rounded-sm bg-pageText text-pageBG text-xxs leading-tight tracking-widest font-semibold uppercase;
      }
    }
  }

  &--title {
    @apply pr-8 sm:pr-12;
  }

  &--name {
    @apply m-0 text-3xl leading-snug;
  }

  &--variant {
    @apply flex items-center mb-2 text-sm leading-relaxed tracking-wider font-semibold uppercase;

    .label {
      @apply -my-1 transform scale-90;
    }
  }

  &--options {
    @apply mt-12;
  }

  &--actions {
    @apply sm:flex sticky bottom-0 py-4 bg-pageBG;
    /* @apply sm:flex sticky bottom-0 mt-4 p-4 md:p-8 border-t bg-pageBG is-textured text-center; */

    button {
      @apply bg-transparent my-3 px-0 text-base font-semibold;
    }

    .counter {
      @apply hidden sm:inline-flex;
    }
  }

  &--actions-learn-more {
    @apply ml-3 py-4;
  }

  &--waitlist {
    @apply relative w-full mt-8;
  }

  &--waitlist-description {
    @apply text-xs text-left font-bold;
  }

  &--learn-more {
    @apply flex flex-row items-center relative mt-8;

    svg {
      @apply h-4;
    }
  }
}

.option {
  @apply flex mt-4 py-4 border-t;

  &--title {
    @apply mr-12 text-xs font-semibold uppercase tracking-wider;
  }

  &--values {
    @apply flex flex-wrap justify-end gap-2 gap-y-2 gap-x-2 ml-auto;
    grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
  }

  &--swatch {
    @apply clean-btn p-0 bg-transparent;
  }
}

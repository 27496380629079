.footer {
  @apply relative overflow-hidden text-sm ;

  &--grid {
    @apply grid sm:grid-cols-2 lg:grid-cols-4 gap-px;
  }

  &--block {
    @apply flex flex-col p-8 md:p-8 bg-pageBG text-center lg:text-left;
  }

  .form {
    @apply mt-auto;
  }

  .menu-footer {
    @apply flex flex-col mt-1 lg:mb-8 list-none;

    a {
      @apply inline-block py-1 hover:opacity-40;
    }
  }

  .menu-social {
    @apply inline-flex flex-wrap my-4 mx-auto lg:mt-0 lg:mx-0;

    a {
      @apply block w-8 h-8 hover:opacity-40;

      svg {
        @apply block w-full h-full;
      }
    }
  }

  &--extras {
    @apply mt-auto;
  }

  &--locale {
    @apply pb-2;
  }

  &--disclaimer {
    @apply mt-3;
    a {
      @apply text-xs;

      &:not(:first-child) {
        @apply ml-3;
      }
    }
  }

  &--logo {
    @apply w-5;
  }
}

.clean-btn {
  @apply appearance-none no-underline cursor-pointer font-inherit;
}

.sr-only {
  @apply absolute m-0 p-0 border-0 w-px h-px overflow-hidden whitespace-nowrap;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

.is-textured {
  background-image: radial-gradient(var(--pageText) 9%, transparent 9%), radial-gradient(var(--pageText) 9%, transparent 9%);
  background-position: 0 0, 8px 8px;
  background-size: 16px 16px;

}

.is-textured-dark {
  background-color: var(--pageText);
  background-image: radial-gradient(var(--pageBG) 9%, transparent 9%), radial-gradient(var(--pageBG) 9%, transparent 9%);
  background-position: 0 0, 8px 8px;
  background-size: 16px 16px;
}

@keyframes pulse {
  0% {
		box-shadow: 0 0 0 0 hsla(var(--pageText-hsl), 0.3);
  }
  70% {
		box-shadow: 0 0 0 8px hsla(var(--pageText-hsl), 0);
  }
  100% {
		box-shadow: 0 0 0 0 hsla(var(--pageText-hsl), 0);
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 20s linear infinite;
}

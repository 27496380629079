*, ::before, ::after {
  border-color: currentColor
}

.container {
  width: 100%
}

@media (min-width: 480px) {
  .container {
    max-width: 480px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 940px) {
  .container {
    max-width: 940px
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1600px
  }
}

.visible {
  visibility: visible
}

.static {
  position: static
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px
}

.z-10 {
  z-index: 10
}

.col-span-1 {
  grid-column: span 1 / span 1
}

.col-span-2 {
  grid-column: span 2 / span 2
}

.col-span-3 {
  grid-column: span 3 / span 3
}

.col-span-4 {
  grid-column: span 4 / span 4
}

.col-span-5 {
  grid-column: span 5 / span 5
}

.col-span-6 {
  grid-column: span 6 / span 6
}

.col-span-7 {
  grid-column: span 7 / span 7
}

.col-span-8 {
  grid-column: span 8 / span 8
}

.col-span-9 {
  grid-column: span 9 / span 9
}

.col-span-10 {
  grid-column: span 10 / span 10
}

.col-span-11 {
  grid-column: span 11 / span 11
}

.col-span-12 {
  grid-column: span 12 / span 12
}

.col-span-full {
  grid-column: 1 / -1
}

.col-start-1 {
  grid-column-start: 1
}

.col-start-2 {
  grid-column-start: 2
}

.col-start-3 {
  grid-column-start: 3
}

.col-start-4 {
  grid-column-start: 4
}

.col-start-5 {
  grid-column-start: 5
}

.col-start-6 {
  grid-column-start: 6
}

.col-start-7 {
  grid-column-start: 7
}

.col-start-8 {
  grid-column-start: 8
}

.col-start-9 {
  grid-column-start: 9
}

.col-start-10 {
  grid-column-start: 10
}

.col-start-11 {
  grid-column-start: 11
}

.col-start-12 {
  grid-column-start: 12
}

.col-start-13 {
  grid-column-start: 13
}

.col-start-auto {
  grid-column-start: auto
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.mt-0 {
  margin-top: 0px
}

.mt-3 {
  margin-top: 0.75rem
}

.mt-10 {
  margin-top: 2.5rem
}

.mb-3 {
  margin-bottom: 0.75rem
}

.mb-10 {
  margin-bottom: 2.5rem
}

.block {
  display: block
}

.flex {
  display: flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.hidden {
  display: none
}

.h-5 {
  height: 1.25rem
}

.h-6 {
  height: 1.5rem
}

.min-h-screen {
  min-height: 100vh
}

.w-5 {
  width: 1.25rem
}

.w-1\/4 {
  width: 25%
}

.w-2\/4 {
  width: 50%
}

.w-3\/4 {
  width: 75%
}

.w-full {
  width: 100%
}

.max-w-0 {
  max-width: 0rem
}

.max-w-none {
  max-width: none
}

.max-w-xs {
  max-width: 20rem
}

.max-w-sm {
  max-width: 24rem
}

.max-w-md {
  max-width: 28rem
}

.max-w-lg {
  max-width: 32rem
}

.max-w-xl {
  max-width: 36rem
}

.max-w-2xl {
  max-width: 42rem
}

.max-w-3xl {
  max-width: 48rem
}

.max-w-4xl {
  max-width: 56rem
}

.max-w-5xl {
  max-width: 64rem
}

.max-w-6xl {
  max-width: 72rem
}

.max-w-7xl {
  max-width: 80rem
}

.max-w-full {
  max-width: 100%
}

.max-w-min {
  max-width: -webkit-min-content;
  max-width: -moz-min-content;
  max-width: min-content
}

.max-w-max {
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content
}

.max-w-prose {
  max-width: 65ch
}

.max-w-screen-xs {
  max-width: 480px
}

.max-w-screen-sm {
  max-width: 768px
}

.max-w-screen-md {
  max-width: 940px
}

.max-w-screen-lg {
  max-width: 1200px
}

.max-w-screen-xl {
  max-width: 1600px
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

@-webkit-keyframes spin {
  to {
    transform: rotate(360deg)
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5
  }
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

.resize {
  resize: both
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr))
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr))
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr))
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr))
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr))
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr))
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr))
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr))
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr))
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr))
}

.grid-cols-none {
  grid-template-columns: none
}

.flex-col {
  flex-direction: column
}

.flex-col-reverse {
  flex-direction: column-reverse
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.justify-center {
  justify-content: center
}

.gap-x-4 {
  grid-column-gap: 1rem;
  -moz-column-gap: 1rem;
       column-gap: 1rem
}

.gap-y-4 {
  grid-row-gap: 1rem;
  row-gap: 1rem
}

.self-auto {
  align-self: auto
}

.self-start {
  align-self: flex-start
}

.self-end {
  align-self: flex-end
}

.self-center {
  align-self: center
}

.self-stretch {
  align-self: stretch
}

.self-baseline {
  align-self: baseline
}

.justify-self-auto {
  justify-self: auto
}

.justify-self-start {
  justify-self: start
}

.justify-self-end {
  justify-self: end
}

.justify-self-center {
  justify-self: center
}

.justify-self-stretch {
  justify-self: stretch
}

.overflow-hidden {
  overflow: hidden
}

.rounded-lg {
  border-radius: 0.5rem
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

.p-4 {
  padding: 1rem
}

.p-8 {
  padding: 2rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-justify {
  text-align: justify
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem
}

.text-5xl {
  font-size: 3rem;
  line-height: 1
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1
}

.text-8xl {
  font-size: 6rem;
  line-height: 1
}

.text-9xl {
  font-size: 8rem;
  line-height: 1
}

.text-xxs {
  font-size: .625rem
}

.font-medium {
  font-weight: 500
}

.text-transparent {
  color: transparent
}

.text-current {
  color: currentColor
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity))
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.text-pageBG {
  color: var(--pageBG)
}

.text-pageBGDark {
  color: var(--pageBGDark)
}

.text-pageText {
  color: var(--pageText)
}

.text-pageTheme {
  color: var(--pageTheme)
}

.text-pagePrimary {
  color: var(--pagePrimary)
}

.text-pageSecondary {
  color: var(--pageSecondary)
}

.text-pageHighlight {
  color: var(--pageHighlight)
}

.text-pageDark {
  color: var(--pageDark)
}

.text-opacity-0 {
  --tw-text-opacity: 0
}

.text-opacity-5 {
  --tw-text-opacity: 0.05
}

.text-opacity-10 {
  --tw-text-opacity: 0.1
}

.text-opacity-20 {
  --tw-text-opacity: 0.2
}

.text-opacity-25 {
  --tw-text-opacity: 0.25
}

.text-opacity-30 {
  --tw-text-opacity: 0.3
}

.text-opacity-40 {
  --tw-text-opacity: 0.4
}

.text-opacity-50 {
  --tw-text-opacity: 0.5
}

.text-opacity-60 {
  --tw-text-opacity: 0.6
}

.text-opacity-70 {
  --tw-text-opacity: 0.7
}

.text-opacity-75 {
  --tw-text-opacity: 0.75
}

.text-opacity-80 {
  --tw-text-opacity: 0.8
}

.text-opacity-90 {
  --tw-text-opacity: 0.9
}

.text-opacity-95 {
  --tw-text-opacity: 0.95
}

.text-opacity-100 {
  --tw-text-opacity: 1
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000
}

.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.grayscale {
  --tw-grayscale: grayscale(100%)
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

@media (min-width: 480px) {
  .xs\:col-span-1 {
    grid-column: span 1 / span 1
  }

  .xs\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .xs\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .xs\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .xs\:col-span-5 {
    grid-column: span 5 / span 5
  }

  .xs\:col-span-6 {
    grid-column: span 6 / span 6
  }

  .xs\:col-span-7 {
    grid-column: span 7 / span 7
  }

  .xs\:col-span-8 {
    grid-column: span 8 / span 8
  }

  .xs\:col-span-9 {
    grid-column: span 9 / span 9
  }

  .xs\:col-span-10 {
    grid-column: span 10 / span 10
  }

  .xs\:col-span-11 {
    grid-column: span 11 / span 11
  }

  .xs\:col-span-12 {
    grid-column: span 12 / span 12
  }

  .xs\:col-span-full {
    grid-column: 1 / -1
  }

  .xs\:col-start-1 {
    grid-column-start: 1
  }

  .xs\:col-start-2 {
    grid-column-start: 2
  }

  .xs\:col-start-3 {
    grid-column-start: 3
  }

  .xs\:col-start-4 {
    grid-column-start: 4
  }

  .xs\:col-start-5 {
    grid-column-start: 5
  }

  .xs\:col-start-6 {
    grid-column-start: 6
  }

  .xs\:col-start-7 {
    grid-column-start: 7
  }

  .xs\:col-start-8 {
    grid-column-start: 8
  }

  .xs\:col-start-9 {
    grid-column-start: 9
  }

  .xs\:col-start-10 {
    grid-column-start: 10
  }

  .xs\:col-start-11 {
    grid-column-start: 11
  }

  .xs\:col-start-12 {
    grid-column-start: 12
  }

  .xs\:col-start-13 {
    grid-column-start: 13
  }

  .xs\:col-start-auto {
    grid-column-start: auto
  }

  .xs\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .xs\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .xs\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .xs\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .xs\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .xs\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .xs\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  .xs\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .xs\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .xs\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .xs\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  .xs\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .xs\:grid-cols-none {
    grid-template-columns: none
  }

  .xs\:self-auto {
    align-self: auto
  }

  .xs\:self-start {
    align-self: flex-start
  }

  .xs\:self-end {
    align-self: flex-end
  }

  .xs\:self-center {
    align-self: center
  }

  .xs\:self-stretch {
    align-self: stretch
  }

  .xs\:self-baseline {
    align-self: baseline
  }

  .xs\:justify-self-auto {
    justify-self: auto
  }

  .xs\:justify-self-start {
    justify-self: start
  }

  .xs\:justify-self-end {
    justify-self: end
  }

  .xs\:justify-self-center {
    justify-self: center
  }

  .xs\:justify-self-stretch {
    justify-self: stretch
  }
}

@media (min-width: 768px) {
  .sm\:order-last {
    order: 9999
  }

  .sm\:col-span-1 {
    grid-column: span 1 / span 1
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .sm\:col-span-5 {
    grid-column: span 5 / span 5
  }

  .sm\:col-span-6 {
    grid-column: span 6 / span 6
  }

  .sm\:col-span-7 {
    grid-column: span 7 / span 7
  }

  .sm\:col-span-8 {
    grid-column: span 8 / span 8
  }

  .sm\:col-span-9 {
    grid-column: span 9 / span 9
  }

  .sm\:col-span-10 {
    grid-column: span 10 / span 10
  }

  .sm\:col-span-11 {
    grid-column: span 11 / span 11
  }

  .sm\:col-span-12 {
    grid-column: span 12 / span 12
  }

  .sm\:col-span-full {
    grid-column: 1 / -1
  }

  .sm\:col-start-1 {
    grid-column-start: 1
  }

  .sm\:col-start-2 {
    grid-column-start: 2
  }

  .sm\:col-start-3 {
    grid-column-start: 3
  }

  .sm\:col-start-4 {
    grid-column-start: 4
  }

  .sm\:col-start-5 {
    grid-column-start: 5
  }

  .sm\:col-start-6 {
    grid-column-start: 6
  }

  .sm\:col-start-7 {
    grid-column-start: 7
  }

  .sm\:col-start-8 {
    grid-column-start: 8
  }

  .sm\:col-start-9 {
    grid-column-start: 9
  }

  .sm\:col-start-10 {
    grid-column-start: 10
  }

  .sm\:col-start-11 {
    grid-column-start: 11
  }

  .sm\:col-start-12 {
    grid-column-start: 12
  }

  .sm\:col-start-13 {
    grid-column-start: 13
  }

  .sm\:col-start-auto {
    grid-column-start: auto
  }

  .sm\:w-1 {
    width: 0.25rem
  }

  .sm\:w-2 {
    width: 0.5rem
  }

  .sm\:w-3 {
    width: 0.75rem
  }

  .sm\:w-4 {
    width: 1rem
  }

  .sm\:w-5 {
    width: 1.25rem
  }

  .sm\:w-6 {
    width: 1.5rem
  }

  .sm\:w-10 {
    width: 2.5rem
  }

  .sm\:w-11 {
    width: 2.75rem
  }

  .sm\:w-12 {
    width: 3rem
  }

  .sm\:w-14 {
    width: 3.5rem
  }

  .sm\:w-16 {
    width: 4rem
  }

  .sm\:w-20 {
    width: 5rem
  }

  .sm\:w-24 {
    width: 6rem
  }

  .sm\:w-28 {
    width: 7rem
  }

  .sm\:w-32 {
    width: 8rem
  }

  .sm\:w-36 {
    width: 9rem
  }

  .sm\:w-40 {
    width: 10rem
  }

  .sm\:w-44 {
    width: 11rem
  }

  .sm\:w-48 {
    width: 12rem
  }

  .sm\:w-52 {
    width: 13rem
  }

  .sm\:w-56 {
    width: 14rem
  }

  .sm\:w-60 {
    width: 15rem
  }

  .sm\:w-64 {
    width: 16rem
  }

  .sm\:w-1\.5 {
    width: 0.375rem
  }

  .sm\:w-2\.5 {
    width: 0.625rem
  }

  .sm\:w-3\.5 {
    width: 0.875rem
  }

  .sm\:w-1\/2 {
    width: 50%
  }

  .sm\:w-1\/3 {
    width: 33.333333%
  }

  .sm\:w-2\/3 {
    width: 66.666667%
  }

  .sm\:w-1\/4 {
    width: 25%
  }

  .sm\:w-2\/4 {
    width: 50%
  }

  .sm\:w-3\/4 {
    width: 75%
  }

  .sm\:w-1\/5 {
    width: 20%
  }

  .sm\:w-2\/5 {
    width: 40%
  }

  .sm\:w-3\/5 {
    width: 60%
  }

  .sm\:w-4\/5 {
    width: 80%
  }

  .sm\:w-1\/6 {
    width: 16.666667%
  }

  .sm\:w-2\/6 {
    width: 33.333333%
  }

  .sm\:w-3\/6 {
    width: 50%
  }

  .sm\:w-4\/6 {
    width: 66.666667%
  }

  .sm\:w-5\/6 {
    width: 83.333333%
  }

  .sm\:w-1\/12 {
    width: 8.333333%
  }

  .sm\:w-2\/12 {
    width: 16.666667%
  }

  .sm\:w-3\/12 {
    width: 25%
  }

  .sm\:w-4\/12 {
    width: 33.333333%
  }

  .sm\:w-5\/12 {
    width: 41.666667%
  }

  .sm\:w-6\/12 {
    width: 50%
  }

  .sm\:w-10\/12 {
    width: 83.333333%
  }

  .sm\:w-11\/12 {
    width: 91.666667%
  }

  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .sm\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  .sm\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .sm\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .sm\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .sm\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  .sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .sm\:grid-cols-none {
    grid-template-columns: none
  }

  .sm\:flex-row {
    flex-direction: row
  }

  .sm\:gap-x-8 {
    grid-column-gap: 2rem;
    -moz-column-gap: 2rem;
         column-gap: 2rem
  }

  .sm\:self-auto {
    align-self: auto
  }

  .sm\:self-start {
    align-self: flex-start
  }

  .sm\:self-end {
    align-self: flex-end
  }

  .sm\:self-center {
    align-self: center
  }

  .sm\:self-stretch {
    align-self: stretch
  }

  .sm\:self-baseline {
    align-self: baseline
  }

  .sm\:justify-self-auto {
    justify-self: auto
  }

  .sm\:justify-self-start {
    justify-self: start
  }

  .sm\:justify-self-end {
    justify-self: end
  }

  .sm\:justify-self-center {
    justify-self: center
  }

  .sm\:justify-self-stretch {
    justify-self: stretch
  }

  .sm\:p-8 {
    padding: 2rem
  }
}

@media (min-width: 940px) {
  .md\:col-span-1 {
    grid-column: span 1 / span 1
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8
  }

  .md\:col-span-9 {
    grid-column: span 9 / span 9
  }

  .md\:col-span-10 {
    grid-column: span 10 / span 10
  }

  .md\:col-span-11 {
    grid-column: span 11 / span 11
  }

  .md\:col-span-12 {
    grid-column: span 12 / span 12
  }

  .md\:col-span-full {
    grid-column: 1 / -1
  }

  .md\:col-start-1 {
    grid-column-start: 1
  }

  .md\:col-start-2 {
    grid-column-start: 2
  }

  .md\:col-start-3 {
    grid-column-start: 3
  }

  .md\:col-start-4 {
    grid-column-start: 4
  }

  .md\:col-start-5 {
    grid-column-start: 5
  }

  .md\:col-start-6 {
    grid-column-start: 6
  }

  .md\:col-start-7 {
    grid-column-start: 7
  }

  .md\:col-start-8 {
    grid-column-start: 8
  }

  .md\:col-start-9 {
    grid-column-start: 9
  }

  .md\:col-start-10 {
    grid-column-start: 10
  }

  .md\:col-start-11 {
    grid-column-start: 11
  }

  .md\:col-start-12 {
    grid-column-start: 12
  }

  .md\:col-start-13 {
    grid-column-start: 13
  }

  .md\:col-start-auto {
    grid-column-start: auto
  }

  .md\:mt-0 {
    margin-top: 0px
  }

  .md\:ml-auto {
    margin-left: auto
  }

  .md\:w-1\/2 {
    width: 50%
  }

  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .md\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .md\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .md\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .md\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .md\:grid-cols-none {
    grid-template-columns: none
  }

  .md\:self-auto {
    align-self: auto
  }

  .md\:self-start {
    align-self: flex-start
  }

  .md\:self-end {
    align-self: flex-end
  }

  .md\:self-center {
    align-self: center
  }

  .md\:self-stretch {
    align-self: stretch
  }

  .md\:self-baseline {
    align-self: baseline
  }

  .md\:justify-self-auto {
    justify-self: auto
  }

  .md\:justify-self-start {
    justify-self: start
  }

  .md\:justify-self-end {
    justify-self: end
  }

  .md\:justify-self-center {
    justify-self: center
  }

  .md\:justify-self-stretch {
    justify-self: stretch
  }
}

@media (min-width: 1200px) {
  .lg\:col-span-1 {
    grid-column: span 1 / span 1
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5
  }

  .lg\:col-span-6 {
    grid-column: span 6 / span 6
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8
  }

  .lg\:col-span-9 {
    grid-column: span 9 / span 9
  }

  .lg\:col-span-10 {
    grid-column: span 10 / span 10
  }

  .lg\:col-span-11 {
    grid-column: span 11 / span 11
  }

  .lg\:col-span-12 {
    grid-column: span 12 / span 12
  }

  .lg\:col-span-full {
    grid-column: 1 / -1
  }

  .lg\:col-start-1 {
    grid-column-start: 1
  }

  .lg\:col-start-2 {
    grid-column-start: 2
  }

  .lg\:col-start-3 {
    grid-column-start: 3
  }

  .lg\:col-start-4 {
    grid-column-start: 4
  }

  .lg\:col-start-5 {
    grid-column-start: 5
  }

  .lg\:col-start-6 {
    grid-column-start: 6
  }

  .lg\:col-start-7 {
    grid-column-start: 7
  }

  .lg\:col-start-8 {
    grid-column-start: 8
  }

  .lg\:col-start-9 {
    grid-column-start: 9
  }

  .lg\:col-start-10 {
    grid-column-start: 10
  }

  .lg\:col-start-11 {
    grid-column-start: 11
  }

  .lg\:col-start-12 {
    grid-column-start: 12
  }

  .lg\:col-start-13 {
    grid-column-start: 13
  }

  .lg\:col-start-auto {
    grid-column-start: auto
  }

  .lg\:w-1\/3 {
    width: 33.333333%
  }

  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .lg\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .lg\:grid-cols-none {
    grid-template-columns: none
  }

  .lg\:gap-x-12 {
    grid-column-gap: 3rem;
    -moz-column-gap: 3rem;
         column-gap: 3rem
  }

  .lg\:gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .lg\:self-auto {
    align-self: auto
  }

  .lg\:self-start {
    align-self: flex-start
  }

  .lg\:self-end {
    align-self: flex-end
  }

  .lg\:self-center {
    align-self: center
  }

  .lg\:self-stretch {
    align-self: stretch
  }

  .lg\:self-baseline {
    align-self: baseline
  }

  .lg\:justify-self-auto {
    justify-self: auto
  }

  .lg\:justify-self-start {
    justify-self: start
  }

  .lg\:justify-self-end {
    justify-self: end
  }

  .lg\:justify-self-center {
    justify-self: center
  }

  .lg\:justify-self-stretch {
    justify-self: stretch
  }
}

@media (min-width: 1600px) {
  .xl\:col-span-1 {
    grid-column: span 1 / span 1
  }

  .xl\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .xl\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .xl\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .xl\:col-span-5 {
    grid-column: span 5 / span 5
  }

  .xl\:col-span-6 {
    grid-column: span 6 / span 6
  }

  .xl\:col-span-7 {
    grid-column: span 7 / span 7
  }

  .xl\:col-span-8 {
    grid-column: span 8 / span 8
  }

  .xl\:col-span-9 {
    grid-column: span 9 / span 9
  }

  .xl\:col-span-10 {
    grid-column: span 10 / span 10
  }

  .xl\:col-span-11 {
    grid-column: span 11 / span 11
  }

  .xl\:col-span-12 {
    grid-column: span 12 / span 12
  }

  .xl\:col-span-full {
    grid-column: 1 / -1
  }

  .xl\:col-start-1 {
    grid-column-start: 1
  }

  .xl\:col-start-2 {
    grid-column-start: 2
  }

  .xl\:col-start-3 {
    grid-column-start: 3
  }

  .xl\:col-start-4 {
    grid-column-start: 4
  }

  .xl\:col-start-5 {
    grid-column-start: 5
  }

  .xl\:col-start-6 {
    grid-column-start: 6
  }

  .xl\:col-start-7 {
    grid-column-start: 7
  }

  .xl\:col-start-8 {
    grid-column-start: 8
  }

  .xl\:col-start-9 {
    grid-column-start: 9
  }

  .xl\:col-start-10 {
    grid-column-start: 10
  }

  .xl\:col-start-11 {
    grid-column-start: 11
  }

  .xl\:col-start-12 {
    grid-column-start: 12
  }

  .xl\:col-start-13 {
    grid-column-start: 13
  }

  .xl\:col-start-auto {
    grid-column-start: auto
  }

  .xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  .xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .xl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .xl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  .xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .xl\:grid-cols-none {
    grid-template-columns: none
  }

  .xl\:self-auto {
    align-self: auto
  }

  .xl\:self-start {
    align-self: flex-start
  }

  .xl\:self-end {
    align-self: flex-end
  }

  .xl\:self-center {
    align-self: center
  }

  .xl\:self-stretch {
    align-self: stretch
  }

  .xl\:self-baseline {
    align-self: baseline
  }

  .xl\:justify-self-auto {
    justify-self: auto
  }

  .xl\:justify-self-start {
    justify-self: start
  }

  .xl\:justify-self-end {
    justify-self: end
  }

  .xl\:justify-self-center {
    justify-self: center
  }

  .xl\:justify-self-stretch {
    justify-self: stretch
  }
}

*,
::before,
::after {
  border: 0px solid;
  box-sizing: border-box;
}
 
::before,
::after {
  vertical-align: inherit;
}
 
html {
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

main {
  display: block;
}

figure {
  margin: 0;
}

hr {
  color: inherit;
  height: 0;
  overflow: visible;
}

nav ol,
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

small {
  font-size: 75%;
}

::-moz-selection {
  text-shadow: none;
}

::selection {
  text-shadow: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img,
iframe {
  border-style: none;
}

svg:not([fill]) {
  fill: currentColor;
}
 
svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
}

button,
input,
select,
textarea {
  margin: 0;
}
 
button {
  overflow: visible;
  text-transform: none;
  color: inherit;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  margin: 0;
  overflow: auto;
  resize: vertical;
  resize: block;
}
 
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}
 
summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
}
 

[aria-busy="true"] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled="true"],
[disabled] {
  cursor: not-allowed;
}

[aria-hidden="false"][hidden] {
  display: initial;
}

[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}
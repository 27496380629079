.promo-bar {
  @apply relative z-60 bg-pageText text-pageBG text-center whitespace-nowrap overflow-x-auto overflow-y-hidden;
  scroll-behavior: smooth;

  &--content {
    @apply inline-flex px-8 py-2 text-sm font-semibold;
  }

  &--link {
    @apply block;
  }
}
.timeline-section {
  /* @apply container px-5 py-24 mx-auto relative; */
  @apply container mx-auto p-4 sm:p-8 border-t border-pageBGDark;

  &--wrapper {
    @apply flex flex-wrap w-full;
  }

  &--timeline {
    @apply lg:w-2/5 md:w-1/2 md:pr-10 md:py-6;
  }

  &--event {
    @apply flex relative pb-12;
  }

  &--line {
    @apply h-full w-10 absolute inset-0 flex items-center justify-center;
  }

  &--line-fill {
    @apply h-full w-1 bg-pageText pointer-events-none;
  }

  &--icon {
    @apply flex-shrink-0 w-10 h-10 rounded-full bg-pageText inline-flex items-center justify-center text-white relative z-10;
  }

  &--last-event {
    @apply flex relative;
  }

  &--content {
    @apply flex-grow pl-4;
  }

  &--title {
    @apply font-medium text-sm mb-1;
  }

  &--visual {
    @apply w-full lg:w-3/5 md:w-1/2 object-cover object-center  md:mt-0 mt-12;
  }
}
